import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    GridRowsProp,
    DataGrid, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel
} from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import './dataGrid.css'

export default function MuiDataGrid(props: any) {
    const { t } = useTranslation();
    const { subjectData,setPercentageValues } = props;
    // console.log(subjectData, "subjectData");

    const generateRowsData = (subjects: any) => {
        return subjects?.map((subject: any) => ({
            id: subject?.subject_id,
            percentage: "",
            subject: subject?.subject_name,
            totalMarks: '100',
            obtainedMarks: ""
        }));
    };

    const [rowsData, setRowsData] = React.useState<any>([]);
    // const [percentageValues, setPercentageValues] = React.useState<any>([]);
    React.useEffect(() => {
        if (subjectData) {
            const res = generateRowsData(subjectData?.data);
            setRowsData(res);
            const initialPercentages = res?.map(() => 0);
            setPercentageValues(initialPercentages);
        }
    }, [subjectData]);

    const columns: GridColDef[] = React.useMemo(() => [
        {
            field: 'subject',
            headerName: t('dataGrid.subject'),
            headerClassName: 'super-app-theme--header',
            cellClassName: "data-grid-subject-cell",
            flex: 1,
            editable: false,
            renderHeader: () => (
                <span> {t('dataGrid.subject')}</span>
            ),
            getCellClassName: () => 'data-grid-subject-cell',
        },
        {
            field: 'totalMarks',
            headerName: t('dataGrid.totalMarks'),
            flex: 1,
            type: 'number',
            editable: true,
            headerClassName: 'super-app-theme--header',
            cellClassName: "data-grid-column-separator",
            renderHeader: () => (
                <span> {t('dataGrid.totalMarks')}</span>
            ),
        },
        {
            field: 'obtainedMarks',
            flex: 1,
            type: 'number',
            editable: true,
            headerClassName: 'super-app-theme--header',
            cellClassName: "data-grid-column-separator",
            renderHeader: () => (
                <span> {t('dataGrid.obtainedMarks')}</span>
            ),
        },
        {
            field: 'percentage',
            flex: 1,
            type: 'number',
            headerName: t('dataGrid.obtainedPercentage'),
            width: 200,
            headerClassName: 'super-app-theme--header',
            renderHeader: () => (
                <span> {t('dataGrid.obtainedPercentage')}</span>
            ),
        },
    ], [t]);

    const [rows, setRows] = React.useState(rowsData);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const processRowUpdate = (newRow: GridRowModel) => {
        const totalMarks = parseFloat(newRow.totalMarks as string) || 0;
        const obtainedMarks = parseFloat(newRow.obtainedMarks as string) || 0;
        const percentage = totalMarks > 0 ? (obtainedMarks / totalMarks) * 100 : 0;

        const updatedRow = {
            ...newRow,
            isNew: false,
            percentage: percentage.toFixed(2) // Formatting to two decimal places
        };
        setPercentageValues((prev:any) => {
            const index = rowsData.findIndex((row:any) => row.id === newRow.id);
            const updatedPercentages = [...prev];
            updatedPercentages[index] = percentage;
            return updatedPercentages;
        });
        setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };
    // useEffect(() => {
    //     console.log(percentageValues, "perentageValue");

    // }, [percentageValues])
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    return (
        <Box sx={{ width: '100%', mt: "1rem" }}>
            <DataGrid
                rows={rowsData}
                columns={columns}
                autoHeight
                hideFooter
                disableRowSelectionOnClick
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
            />
        </Box>
    );
}
