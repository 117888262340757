import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FeedbackData = () => {
    const { t } = useTranslation();
    const [feedBackFormData, setFeedBackFormData] = useState({
        studentsFeedback: '',
        attentionNeeded: "",
        goal: "",
        mentorName: "",
        whatsAppNo: ""
    })
    useEffect(() => {

    }, [feedBackFormData])
    return (
        <div className="border-black border-2 ">
            <div>
                <div className="bg-[#ffd700]  border-black border-2 p-2">{t('feedbackData.studentsFeedback')}</div>
                <div className=" border-black border-2 ">
                    <TextField
                        fullWidth
                        placeholder="write something.."
                        onChange={(e) => { setFeedBackFormData((prevValue) => ({ ...prevValue, studentsFeedback: e.target.value })) }}
                    />
                </div>

            </div>
            <div>
                <div className="bg-[#ffd700]  border-black border-2 p-2">{t('feedbackData.attenttionNeeded')}</div>
                <div className=" border-black border-2 ">
                    <TextField
                        fullWidth
                        placeholder="write something.."
                        onChange={(e) => { setFeedBackFormData((prevValue) => ({ ...prevValue, attentionNeeded: e.target.value })) }}
                    />
                </div>

            </div>
            <div>
                <div className="bg-[#ffd700] border-black border-2 p-2">{t('feedbackData.goal')}</div>
                <div className=" border-black border-2 ">
                    <TextField
                        fullWidth
                        placeholder="write something.."
                        className=""
                        onChange={(e) => { setFeedBackFormData((prevValue) => ({ ...prevValue, goal: e.target.value })) }}

                    />
                </div>
            </div>
            <div className="flex">

                <div className="w-1/6 bg-[#ffd700] flex items-center justify-center">{t('feedbackData.mentorName')}</div>
                <div className="w-1/3">
                    <TextField
                        fullWidth
                        placeholder="write something.."
                        onChange={(e) => { setFeedBackFormData((prevValue) => ({ ...prevValue, mentorName: e.target.value })) }}

                    />
                </div>
                <div className="w-1/6 bg-[#ffd700] flex items-center justify-center">{t('feedbackData.whatsAppNum')}</div>
                <div className="w-1/3">
                    <TextField
                        fullWidth
                        type="number"
                        placeholder="write something.."
                        onChange={(e) => { setFeedBackFormData((prevValue) => ({ ...prevValue, whatsAppNo: e.target.value })) }}

                    />
                </div>
            </div>
        </div>
    )
}
export default FeedbackData;