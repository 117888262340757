import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Header from "./header";
import Table from "./subjectTable";
import ColumnChart from "../chart";
import FeedbackData from "./feedbackData";
import { PDFDownloadLink } from "@react-pdf/renderer";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import TablePdfMake from "./pdfMakeGrid";
import { exportComponentAsJPEG } from "react-component-export-image";
import { Button, InputAdornment, TextField } from "@mui/material";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DownloadIcon from '@mui/icons-material/Download';
import { base64encodedAuthData, getStudentSubjectsUrl, getStudentUrl, qParam1, qParam2 } from "../constants";
import axios from "axios";
import { ApiResponse, Class, RequestBodyType, StudentStateInfo } from "../types";
import { enqueueSnackbar, useSnackbar } from "notistack";
// import PdfDocument from "./pdf";

const Report = () => {
    // const { enqueueSnackbar } = useSnackbar();
    const componentRef = useRef<HTMLDivElement>(null);
    const [studentContactNumber, setStudentContactNumber] = useState<number>()
    const [phoneError, setPhoneError] = useState('')
    const [studentData, setStudentData] = useState<StudentStateInfo>({
        name: "",
        classList: [],
    });
    const [requestBody, setRequestBody] = useState<RequestBodyType>({
        footfallId: "",
        classId: ""
    })
    const [subjectData, setSubjectData] = useState<any>([])
    const [percentageValues, setPercentageValues] = React.useState<any>([]);



    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get<ApiResponse>(`${getStudentUrl}${studentContactNumber}`, {
                headers: {
                    'Authorization': `Basic ${base64encodedAuthData}`
                }
            });

            setStudentData((prevValue: StudentStateInfo) => ({
                ...prevValue,
                classList: response.data?.classes || [],
                name: response.data?.data?.student_name || ""
            }));
            setRequestBody((prevValue: RequestBodyType) => ({
                ...prevValue,
                footfallId: response.data.data?.Footfall_Id
            }))
            console.log(response, "response");
            if (response?.status == 200) {

                enqueueSnackbar(response.data.message, { variant: "success" })
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }, [base64encodedAuthData, studentContactNumber]);

    const fectStudentSubject = useCallback(async (classId: string) => {
        const params = new URLSearchParams({
            [qParam1]: String(requestBody.footfallId),
            [qParam2]: classId ? classId : '',
        });
        try {
            const response = await axios.get<any>(`${getStudentSubjectsUrl}${params.toString()}`, {
                headers: {
                    'Authorization': `Basic ${base64encodedAuthData}`
                }
            });

            // console.log(response.data, "subjects");
            setSubjectData(response?.data)
            return response.data;
        } catch (error) {
            console.error('Error:', error);
        }
    }, [requestBody, base64encodedAuthData])

    // useEffect(() => {
    //     fetchData()
    // }, [])


    const handleChange = (event: any) => {
        const { value } = event.target;
        // const phoneRegex = /^[+]?[0-9]{10,15}$/; 

        if (/^\d{0,10}$/.test(value)) {
            setStudentContactNumber(prevValue => {
                return value;
            });
            setPhoneError('');
        } else {
            setPhoneError('Invalid phone number');
        }
    };

    // useEffect(() => {

    //     console.log(studentContactNumber, "contact");

    // }, [studentContactNumber])
    const downloadPDF = () => {
        const input = document.getElementById('component-to-print');
        if (input) {
            html2canvas(input).then((canvas) => {

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const pdfWidth = 210;
                const pdfHeight = 297;

                const imgWidth = canvas.width / 4;
                const imgHeight = canvas.height / 4;

                const ratio = imgWidth / imgHeight;
                let width = pdfWidth;
                let height = pdfWidth / ratio;

                if (height > pdfHeight) {
                    height = pdfHeight;
                    width = pdfHeight * ratio;
                }
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                pdf.save('download.pdf');
            });
        } else {
            console.error('Element with id "component-to-print" not found');
        }
    };
    const { t } = useTranslation();
    return (
        <div className="p-4">
            <div className="flex items-center mb-4">
                <TextField
                    id="phone-number"
                    label="Mobile"
                    value={studentContactNumber}
                    onChange={handleChange}
                    variant="standard"
                    type="text"

                    error={!!phoneError}
                    helperText={phoneError}
                    inputProps={{
                        maxLength: 10,
                    }}
                    InputProps={{

                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneAndroidIcon />
                            </InputAdornment>
                        ),

                    }}
                    sx={{ width: '20rem' }}
                />
                <Button variant="contained" sx={{ backgroundColor: '#0284c7', ml: '10px' }} onClick={fetchData} >Get Data</Button>
                <Button variant="contained" sx={{ marginLeft: 'auto' }} onClick={() => exportComponentAsJPEG(componentRef)}><DownloadIcon /></Button>

            </div>
            <div ref={componentRef} id="component-to-print" className=" border-2 border-slate-500 p-2 ">
                {/* <PDFDownloadLink document={<PdfDocument />} fileName="document.pdf">
                {({ loading }) => loading ? 'Loading document...' : <button>Download PDF</button>}
            </PDFDownloadLink> */}
                <Table
                    studentData={studentData}
                    setRequestBody={setRequestBody}
                    fectStudentSubject={fectStudentSubject}
                    setPercentageValues={setPercentageValues}
                />
                <ColumnChart
                    subjectData={subjectData}
                    percentageValues={percentageValues}
                />
                <FeedbackData />
            </div>
        </div>
    )
}

export default Report;