import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ApexOptions } from "apexcharts";

const ColumnChart = (props: any) => {
    const { subjectData,percentageValues } = props;
    const { t } = useTranslation();

    const [marksData, setMarksData] = useState({
        series: [{
            name: t('dataGrid.subject'),
            data: percentageValues
        }],
        options: {
            chart: {
                height: 350,
                type: "bar" as "bar",
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '20%',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 0
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            xaxis: {
                labels: {
                    rotate: -45
                },
                categories: [],
                tickPlacement: 'on',
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 1,
                    opacity: 0.4,
                },
            },
            yaxis: {
                title: {
                    text: t('dataGrid.subject'),
                },
                min: 0,
                max: 100,
                stepSize: undefined,
                labels: {
                    formatter: (value: number) => value.toFixed(2) 
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                },
            },
            colors: ['#FFD700', '#F1C40F', '#FEB019', '#F39C12']
        } as ApexOptions
    });

    useEffect(() => {
        if (subjectData?.data && subjectData.data.length > 0) {
            const categories = subjectData.data.map((item: any) => item.subject_name);
            const data = subjectData.data.map(() => Math.floor(Math.random() * 100)); // Replace with your actual data

            setMarksData({
                series: [{
                    name: t('dataGrid.subject'),
                    data: percentageValues
                }],
                options: {
                    ...marksData.options,
                    xaxis: {
                        ...marksData.options.xaxis,
                        categories: categories
                    }
                }
            });
        }
    }, [subjectData, t, percentageValues]);

    return (
        <div id="chart" className="w-full">
            <ReactApexChart
                options={marksData.options}
                series={marksData.series}
                type="bar"
                height={350}
            />
        </div>
    )
}

export default ColumnChart;
