import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MuiDataGrid from "./dataGrid";
import ColumnChart from "../chart";
import { Class, RequestBodyType, StudentStateInfo } from "../types";

interface TableProps {
    studentData: StudentStateInfo,
    setRequestBody: React.Dispatch<React.SetStateAction<RequestBodyType>>
    setPercentageValues: React.Dispatch<React.SetStateAction<any>>
    fectStudentSubject: (classId:string) => {}
}

const Table = (props: TableProps) => {
    const { studentData, setRequestBody, fectStudentSubject,setPercentageValues } = props
    const { t } = useTranslation();
    const [studentName, setStudentName] = useState("Jon Snow")
    const [studentClass, setStudentClass] = useState("")
    const [studentClassId, setStudentClassId] = useState("");
    const [subjectData, setSubjectData] = useState<any>([])
    // useEffect(() => {
    //     console.log(studentClassId, "ID");
    // }, [studentClassId])

    const handleChange = async (e: any) => {
        setStudentClassId(e.target.value)
        setRequestBody((prevValue) => ({
            ...prevValue,
            classId: e.target.value
        }))
        const subjectsData: any = await fectStudentSubject( e.target.value)
        // console.log(subjectsData?.data, "subjectsData");
        setSubjectData(subjectsData);

    }
    return (
        <div className="">
            <div className="flex items-center w-full gap-10 ">

                {/* <TextField id="standard-basic" label="Mobile" value={studentContactNumber} type="numeric" variant="standard" sx={{ width: '20rem' }} /> */}
                <TextField
                    id="standard-basic"
                    label="Name"
                    InputLabelProps={{
                        sx: {
                            fontWeight: 'bold',
                        },
                    }}
                    value={studentData.name}
                    variant="standard"
                    sx={{ width: '20rem' }}
                />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
                    <InputLabel id="demo-simple-select-standard-label" sx={{
                        fontWeight: 'bold',
                    }}>Class</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={studentClassId}
                        onChange={handleChange}
                        label="Class"
                    >
                        <MenuItem value={0}>
                            <em>None</em>
                        </MenuItem>
                        {
                            studentData.classList.length > 0 ? studentData.classList.map((student, i) => (
                                <MenuItem key={i} value={student.class_id}>{student.roman_letter}</MenuItem>
                            )) : <MenuItem value={0}><em>None Classes Found</em></MenuItem>
                        }

                    </Select>
                </FormControl>
                {/* <TextField id="standard-basic" label="Class" value={studentClass} variant="standard" sx={{ width: '20rem' }} /> */}
                <div className="ml-auto">
                    <div className="container mx-auto flex justify-between mb-1">
                        <div className="flex-none"></div>
                        <div className="flex-grow flex justify-center ">
                            <span className=" bg-sky-600 rounded p-2 px-3 text-white font-bold text-xl">{t('reportHeading')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Box
                sx={{
                    width: '100%',
                    fontSize: '18px',
                    '& .super-app-theme--header': {
                        backgroundColor: 'gold',
                        fontSize: '18px',
                        fontWeight: '700',
                    },
                    '& .data-grid-subject-cell': {
                        backgroundColor: '#0284c7 ',
                        color: '#ffff',
                        fontSize: '16px',
                    },
                }}
            >
                <MuiDataGrid
                    subjectData={subjectData}
                    setPercentageValues={setPercentageValues}
                />
            </Box>

        </div>
    )
}

export default Table;