import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      dataGrid: {
        subject: "Subject",
        totalMarks: "Total Marks",
        obtainedMarks: "Obtained Marks",
        obtainedPercentage: "Percentage ",
      },
      feedbackData: {
        studentsFeedback: "Opinions shared by students",
        mentorName: "Name of Mentor Teacher",
        whatsAppNum: "Whatsapp Number:",
        attenttionNeeded:"Subjects That Students Need to Focus On",
        goal:"Student's goals for next month and speech to parents"
      },
      name: "Name",
      class: "class",

      reportHeading: "Monthly Progress Report",
    },
  },
  bn: {
    translation: {
      dataGrid: {
        subject: "বিষয়",
        totalMarks: "মোট নম্বর",
        obtainedMarks: "প্রাপ্ত নম্বর",
        obtainedPercentage: "শতাংশ",
      },
      feedbackData: {
        studentsFeedback: "ছাত্র/ছাত্রীর বিষয়ে মতামত",
        mentorName: "মেন্টর টিচারের  নাম :",
        whatsAppNum: "হোয়াটসঅ্যাপ:",
        attenttionNeeded:"ছাত্র/ছাত্রীর যে বিষয় গুলিতে নজর দেওয়া প্রয়োজন ",
        goal:"ছাত্র/ছাত্রীর আগামী মাসের লক্ষ্য ও অভিভাবকের প্রতি বক্তব্য "
      },
      name: "নাম",
      class: "ক্লাস",
      reportHeading: "মাসিক অগ্রগতি রিপোর্ট",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "bn",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
